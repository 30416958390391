import {Link, useParams} from "react-router-dom";
import {MapContainer} from "./MapContainer";
import {Button} from "reactstrap";


export  function MapDisplayPage() {
    let params = useParams();
    
    return (
        <div className = 'map-container'>
            {<MapContainer  currentMap = {params.mapId} currentSku={params.sku} isCustomerView = {params.isCustomer}/>}
            <nav>
                <Link to="/">
                    <Button className = "btn-sm">Home</Button>
                </Link>
            </nav>
        </div>
    );
}