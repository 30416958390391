import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Routes, Route, Link} from 'react-router-dom';
import App from './App';
import "./components/css/Map.css";
import {MapDisplayPage} from "./components/MapDisplayPage";
import {Map} from "./components/Map";
import {Button} from "reactstrap";



const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter>
      <Routes>
          <Route path="/" element={<App />}/>
          <Route path="maps" element={<MapDisplayPage />}>
              <Route path=":mapId" element={<Map />} >
                  <Route path=":isCustomer" element={<Map />}>
                      <Route path=":sku" element = {<Map/>}/>
                  </Route>
              </Route>
          </Route>
          
         
          <Route
              path="*"
              element={
                  <main style={{ padding: "1rem" }}>
                      <p>There's no data for that map store or item</p>
                      <nav>
                          <Link to="/">
                              <Button className = "btn-sm">Home</Button>
                          </Link>
                      </nav>
                  </main>
              }
          />
      </Routes>
  </BrowserRouter>,
  rootElement);



