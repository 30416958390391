import React, {useEffect, useState} from "react";
import {Map} from "./Map";
import "./css/Map.css"

export function MapContainer({currentMap, currentSku, isCustomerView}){

    const [mapJson, setMapJson] = useState();
    const [skuLocations, setSkuLocations] = useState();
    
    useEffect(() => {
        const populateMapData = async () => {
            console.log("Populate called")

            if(currentMap && isCustomerView === "Customer"){
                const response = await fetch(`/api/store-map/${currentMap}/user-type/customer`);

                const data = await response.json();
                setMapJson(data);

                const skuResponse = await fetch(`/api/sku-locations/${currentSku}/store/${currentMap}/user-type/customer`);
                const skuData = await skuResponse.json();
                setSkuLocations(skuData);
            }

            else if (currentMap && isCustomerView === "Associate"){
                const response = await fetch(`/api/store-map/${currentMap}/user-type/associate`);

                const data = await response.json();
                console.log(data)
                setMapJson(data);

                const skuResponse = await fetch(`/api/sku-locations/${currentSku}/store/${currentMap}/user-type/associate`);
                const skuData = await skuResponse.json();
                setSkuLocations(skuData);
            }
        }
        
            populateMapData()
        

    },[currentMap, currentSku, isCustomerView]);
    
    return (
        
       <div className = 'map-container'>
           {<Map storeData={mapJson} skuLocationData={skuLocations} isCustomerView = {isCustomerView}/>}
       </div>
       
    )
}