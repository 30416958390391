import "./css/Map.css"

export function Map({storeData, skuLocationData, isCustomerView}){
    
    //Todo: Refactor styling to happen in css
    return (
        storeData && skuLocationData ? 
        <svg className={"map-svg"}  viewBox={storeData.ViewBox}>

            {isCustomerView  === "Customer" ? (
                <polygon points = {storeData.BoundaryCoordinates.map(coordinate =>(" " + coordinate.x + "," + coordinate.y + " "))}></polygon>
            ) : (
                <></>
            )}

            {storeData.outerGroup.elements[0].elements[0].elements.map((element, i) =>  
                <rect transform = {`translate(${element.x} ${element.y}) rotate(${element.rotation} 0 0)`}
                      width = {element.length} height = "24" rx = "1" fill="lightblue" opacity={.25} strokeWidth="3" stroke="rgb(0,0,0)" />)}

            {storeData.outerGroup.elements[1].elements[0].elements.map((element, i) =>  
                <text className = 'dept-label' x = {element.x} y = {element.y} fontSize={42}>{element.elements[0].value}</text>)}
            }
            {skuLocationData.skuLocations?.map((sku, i) =>(
                    <circle className= 'sku-pin' id = {sku.SKU_ID}  cx = {sku.X} cy = {sku.Y} r="10" fill="red"/>
                ))}
        
        </svg> 
            : <></>
        
        
    )
}


            
