import React from 'react';



//For security home is disabled. It can be cnabled locally to see the native front end for debugging or development

export default function App() {
    
    return (
        //<Home/>
        <></>
    )
}



